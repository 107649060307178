import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SkillTree from './skill-tree.jsx';
import AllocatedSkills from './allocated-skills.jsx';
import axios from 'axios';

export default function CharacterPlanner(props) {

    const { plannerOpen, setPlannerOpen, handleClose, characterItems, skills, setAllSkills, ...other } = props;
    const [characterSelected, setCharacterSelected] = React.useState("Amazon");
    const [allocatedSkills, setAllocatedSkills] = React.useState([]);
    const [remainingSkillPoints, setRemainingSkillPoints] = React.useState(111);

    const handleSave = () => {
        const element = document.createElement("a");
        let buildString =
        `Class: ${characterSelected}\n` +
        `\nSkills:\n`;
        allocatedSkills.forEach(skill => buildString += ` * ${skill.name}: ${skill.value}\n`);
        buildString += `\nGear:\n`;
        characterItems.forEach(item => buildString += ` * ${item.gearId.itemCategory}: ${item.gearId.name}\n`);
        const blob = new Blob([buildString], { type: "text/plain;charset=utf-8" });
        element.href = URL.createObjectURL(blob);
        element.download = `pd2search-${characterSelected}-build.txt`;
        document.body.appendChild(element);
        element.click();
        handleClose();
        setAllocatedSkills([]);
        setRemainingSkillPoints(111);
        setCharacterSelected("Amazon");
    }

     const handleCharacterSelected = (opt) => {
        setAllocatedSkills([]);
        setRemainingSkillPoints(111);
        setCharacterSelected(opt);
     }

     const handleAllocSkillsChanged = (e) => {
        var totalSkillPointsUsed = 0;
        e.forEach(skill => totalSkillPointsUsed+=skill.value);
        setRemainingSkillPoints(111-totalSkillPointsUsed);
        setAllocatedSkills(e);
     }

    return (
     <Box sx={{backgroundColor: '#191919'}}>
        <Dialog
              fullScreen
              open={plannerOpen}
              onClose={handleClose}
              sx={{'&& .MuiDialog-paper': {backgroundColor: '#191919', overflowX: 'clip'}}}
            >
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={handleClose}
                      aria-label="close"
                    >
                      <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                      Character Planner
                    </Typography>
                    <Button color="inherit" onClick={handleSave}>
                        save
                    </Button>
                  </Toolbar>
            </AppBar>
             <Grid container spacing={1} sx={{margin: '1%', }}>
                <Grid item sm={4} md={2} lg={1} xl={1} sx={{'&:hover': {cursor: 'pointer'}}} >
                    <Card sx={{ display: 'flex', flexDirection: 'column', border: '3px solid #a53030', backgroundColor: characterSelected == "Amazon" ? '#303030' : '#191919' }} onClick={() => handleCharacterSelected("Amazon")}>
                      <CardMedia
                        component="img"
                        image={"./amazon.png"}
                        sx={{padding: '15px'}}
                        alt="amazon"
                      />
                    </Card>
                </Grid>
               <Grid item sm={4} md={2} lg={1} xl={1} sx={{'&:hover': {cursor: 'pointer'}}}>
                    <Card sx={{ display: 'flex', flexDirection: 'column', border: '3px solid #a53030', backgroundColor: characterSelected == "Assassin" ? '#303030' : '#191919' }} onClick={() => handleCharacterSelected("Assassin")}>
                      <CardMedia
                        component="img"
                        image={"./assassin.png"}
                        sx={{padding: '15px'}}
                        alt="assassin"
                      />
                    </Card>
                </Grid>
               <Grid item sm={4} md={2} lg={1} xl={1} sx={{'&:hover': {cursor: 'pointer'}}}>
                    <Card sx={{ display: 'flex', flexDirection: 'column', border: '3px solid #a53030', backgroundColor: characterSelected == "Barbarian" ? '#303030' : '#191919' }} onClick={() => handleCharacterSelected("Barbarian")}>
                      <CardMedia
                        component="img"
                        image={"./barbarian.png"}
                        sx={{padding: '15px'}}
                        alt="barbarian"
                      />
                    </Card>
                </Grid>
                <Grid item sm={4} md={2} lg={1} xl={1} sx={{'&:hover': {cursor: 'pointer'}}}>
                    <Card sx={{ display: 'flex', flexDirection: 'column', border: '3px solid #a53030', backgroundColor: characterSelected == "Druid" ? '#303030' : '#191919' }} onClick={() => handleCharacterSelected("Druid")}>
                      <CardMedia
                        component="img"
                        image={"./druid.png"}
                        sx={{padding: '15px'}}
                        alt="druid"
                      />
                    </Card>
                </Grid>
                <Grid item sm={4} md={2} lg={1} xl={1} sx={{'&:hover': {cursor: 'pointer'}}}>
                    <Card sx={{ display: 'flex', flexDirection: 'column', border: '3px solid #a53030', backgroundColor: characterSelected == "Necromancer" ? '#303030' : '#191919' }} onClick={() => handleCharacterSelected("Necromancer")}>
                      <CardMedia
                        component="img"
                        image={"./necromancer.png"}
                        sx={{padding: '15px'}}
                        alt="necromancer"
                      />
                    </Card>
                </Grid>
                <Grid item sm={4} md={2} lg={1} xl={1} sx={{'&:hover': {cursor: 'pointer'}}}>
                    <Card sx={{ display: 'flex', flexDirection: 'column', border: '3px solid #a53030', backgroundColor: characterSelected == "Paladin" ? '#303030' : '#191919' }} onClick={() => handleCharacterSelected("Paladin")}>
                      <CardMedia
                        component="img"
                        image={"./paladin.png"}
                        sx={{padding: '15px'}}
                        alt="paladin"
                      />
                    </Card>
                </Grid>
                <Grid item sm={4} md={2} lg={1} xl={1} sx={{'&:hover': {cursor: 'pointer'}}}>
                    <Card sx={{ display: 'flex', flexDirection: 'column', border: '3px solid #a53030', backgroundColor: characterSelected == "Sorceress" ? '#303030' : '#191919' }} onClick={() => handleCharacterSelected("Sorceress")}>
                      <CardMedia
                        component="img"
                        image={"./sorceress.png"}
                        sx={{padding: '15px'}}
                        alt="sorceress"
                      />
                    </Card>
                </Grid>
            </Grid>
            <AllocatedSkills remainingSkillPoints={remainingSkillPoints} setRemainingSkillPoints={setRemainingSkillPoints} allocatedSkills={allocatedSkills}/>
            <SkillTree characterSelected={characterSelected} skills={skills} allocSkills={allocatedSkills} setAllocSkills={handleAllocSkillsChanged} remainingPoints={remainingSkillPoints}/>
        </Dialog>
     </Box>
    );
}