import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Badge from '@mui/material/Badge';
import Xarrow from 'react-xarrows';
import SkillButton from './skill-button.jsx';

export default function PaladinSkillTree(props) {

    const { skills, allocSkills, setAllocSkills, remainingSkillPoints, ...other } = props;

    let skillTreeImg = "./skillTreeBg-2.png";

    return (
        <Box>
            {skills &&
            <Grid container spacing={1}>
                <Grid item className="skillTreeTab" sm={4} md={4} lg={4} xl={4} sx={{ background: `url(${skillTreeImg}) rgba(50,50,50,.1)`, backgroundSize: "contain", border: 'solid 10px #191919', textAlign: 'center'}}>
                    <Toolbar position="relative" sx={{backgroundColor: 'rgba(90,90,90,0.8)', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', border: 'solid 5px #303030', color: '#FFF', justifyContent: 'center', top: '-28px', width: '75%'}}>
                        <Typography variant="subtitle2" component="div">
                            Defensive Auras
                        </Typography>
                    </Toolbar>
                    <Grid container spacing={1}>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[152]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[153]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />


                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[156]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[154]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[157]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[155]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[158]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[159]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[160]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[161]}/>
                    </Grid>
                </Grid>
                <Grid item className="skillTreeTab" sm={4} md={4} lg={4} xl={4} sx={{ background: `url(${skillTreeImg}) rgba(50,50,50,.1)`, backgroundSize: "contain", border: 'solid 10px #191919', textAlign: 'center'}}>
                    <Toolbar position="relative" sx={{backgroundColor: 'rgba(90,90,90,0.8)', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', border: 'solid 5px #303030', color: '#FFF', justifyContent: 'center', top: '-28px', width: '75%'}}>
                        <Typography variant="subtitle2" component="div">
                            Offensive Auras
                        </Typography>
                    </Toolbar>
                    <Grid container spacing={1}>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[162]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[163]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[164]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[165]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[166]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[167]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[168]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[169]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[170]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[171]}/>
                    </Grid>
                </Grid>
                <Grid item className="skillTreeTab" sm={4} md={4} lg={4} xl={4} sx={{ background: `url(${skillTreeImg}) rgba(50,50,50,.1)`, backgroundSize: "contain", border: 'solid 10px #191919', textAlign: 'center'}}>
                    <Toolbar position="relative" sx={{backgroundColor: 'rgba(90,90,90,0.8)', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', border: 'solid 5px #303030', color: '#FFF', justifyContent: 'center', top: '-28px', width: '75%'}}>
                        <Typography variant="subtitle2" component="div">
                            Combat Skills
                        </Typography>
                    </Toolbar>
                    <Grid container spacing={1}>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[172]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[173]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[174]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[175]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[176]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[177]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[178]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[179]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[180]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[181]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[182]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[183]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[184]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                    </Grid>
                </Grid>
            </Grid>}
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Prayer"
                end="Cleansing"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Prayer"
                end="Defiance"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Defiance"
                end="Vigor"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Cleansing"
                end="Meditation"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Vigor"
                end="Redemption"
                startAnchor="bottom"
                endAnchor="top" />




            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Might"
                end="Blessed Aim"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Might"
                end="Holy Fire"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Holy Fire"
                end="Holy Freeze"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Thorns"
                end="Sanctuary"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Blessed Aim"
                end="Concentration"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Concentration"
                end="Fanaticism"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Holy Freeze"
                end="Holy Shock"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Holy Freeze"
                end="Sanctuary"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Sanctuary"
                end="Conviction"
                startAnchor="bottom"
                endAnchor="top" />



            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Sacrifice"
                end="Zeal"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Holy Bolt"
                end="Holy Light"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Smite"
                end="Charge"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Zeal"
                end="Vengeance"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Holy Light"
                end="Blessed Hammer"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Charge"
                end="Conversion"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Vengeance"
                end="Joust"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Blessed Hammer"
                end="Fist of the Heavens"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Conversion"
                end="Holy Shield"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Fist of the Heavens"
                end="Holy Nova"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Holy Shield"
                end="Holy Nova"
                startAnchor="bottom"
                endAnchor="top" />

        </Box>
    );

}