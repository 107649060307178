import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

export default function SkillButton(props) {

    const { skill, allocSkills, setAllocSkills, remainingSkillPoints, ...other } = props;

    const [allocatedPoints, setAllocatedPoints] = React.useState(0);

    const handleSkillClicked = (e) => {
        e.preventDefault();
        var tempArr = allocSkills.filter((item) => item.name !== skill.name)
        if(e.type === 'contextmenu' && e.shiftKey && allocatedPoints > 0) {
            setAllocatedPoints(0);
            setAllocSkills([...tempArr]);
        } else if(e.type === 'contextmenu' && allocatedPoints > 0) {
            setAllocatedPoints(allocatedPoints-1);
            setAllocSkills([{name: skill.name, value: allocatedPoints-1}, ...tempArr]);
        }
        else if(e.type === 'click' && e.shiftKey && remainingSkillPoints > 0) {
            if(remainingSkillPoints >= 20) {
                setAllocatedPoints(20);
                setAllocSkills([{name: skill.name, value: 20}, ...tempArr]);
            } else {
                setAllocatedPoints(remainingSkillPoints);
                setAllocSkills([{name: skill.name, value: remainingSkillPoints}, ...tempArr]);
            }
        } else if (e.type === 'click' && allocatedPoints < 20 && remainingSkillPoints > 0) {
            setAllocatedPoints(allocatedPoints+1);
            setAllocSkills([{name: skill.name, value: allocatedPoints+1}, ...tempArr]);
        }
    }

    const HtmlTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(0, 0, 0, 0.87)',
        color: 'rgba(255, 255, 255, 0.87)',
        textAlign: 'center',
        fontSize: theme.typography.pxToRem(12),
        maxWidth: '600px',
        border: '1px solid #000000',
      },
    }));

    var skillStats = [];
    var hardSkillStats = [];
    if(skill !== undefined) {
        var skillStatsSplit = skill.skillStats.split("],")
           skillStatsSplit.forEach((skillStat, skillStatIndex) => {
                var descValueSplit = skillStat.split("=");
                skillStats.push({"statDescription": descValueSplit[0], "statValue": descValueSplit[1].replace("[", "").replace("]", "").split(",")});
            });

        var hardSkillStatsSplit = skill.hardSkillStats.split("],")
           hardSkillStatsSplit.forEach((hardSkillStat, skillStatIndex) => {
                var descValueSplit = hardSkillStat.split("=");
                if(descValueSplit[1] !== undefined) {
                    hardSkillStats.push({"hardSkillStatDescription": descValueSplit[0], "hardSkillStatValue": descValueSplit[1].replace("[", "").replace("]", "").split(",")})
                }
            });
    }

    return (
     <Grid item sm={4} md={4} lg={4} xl={4}>
        <Box component="span">
            {skill ?
                <HtmlTooltip
                        title={
                          <React.Fragment>
                            <Typography color="#00F000">{skill.name}</Typography>
                            <em>{skill.description}</em>
                            <Typography variant="subtitle2">{skill.requiredLevel}</Typography>
                            <br/>
                            {allocatedPoints !== 0 && <Box><Typography variant="subtitle2"><u>Current Level: {allocatedPoints}</u></Typography>
                                {skillStats.sort().map(stat => <Typography key={stat.statDescription + stat.statValue[allocatedPoints-1]} variant="subtitle2"> {stat.statDescription}: {stat.statValue[allocatedPoints-1]}</Typography>)}
                                {hardSkillStats.sort().map(stat => <Typography key={stat.hardSkillStatDescription + stat.hardSkillStatValue[allocatedPoints]} variant="subtitle2"> {stat.hardSkillStatDescription}: {stat.hardSkillStatValue[allocatedPoints]}</Typography>)}
                                <br/></Box>}
                            <Typography variant="subtitle2"><u>Next Level:</u></Typography>
                            {skillStats.sort().map(stat => <Typography key={stat.statDescription} variant="subtitle2"> {stat.statDescription}: {stat.statValue[allocatedPoints]}</Typography>)}
                            {allocatedPoints!== 20 && hardSkillStats.sort().map(stat => <Typography key={stat.hardSkillStatDescription} variant="subtitle2"> {stat.hardSkillStatDescription}: {stat.hardSkillStatValue[allocatedPoints+1]}</Typography>)}
                            <br/>
                            {skill.synergies !== "No Synergies" && <Box><Typography variant="subtitle2" color="#00F000"><u>{skill.name} receives bonuses from:</u></Typography>
                                {skill.synergies.split(",").map(synergy => <Typography key={synergy} variant="subtitle2">{synergy}</Typography>)}</Box> }
                          </React.Fragment>
                        }
                        placement="right-start"
                        disableInteractive
                      >
                    <Badge badgeContent={allocatedPoints} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} sx={{"& .MuiBadge-badge": {color: '#FFF', backgroundColor: '#303030', borderRadius: '0', zIndex: "10"}}}>
                        <IconButton sx={{borderRadius: '0', marginTop:'5%', zIndex: '4'}} onClick={handleSkillClicked} onContextMenu={handleSkillClicked}> <img id={skill.name} src={skill.imageUrl} height="160%" className={allocatedPoints == 0 ? "unallocatedSkill" : ""}/></IconButton>
                    </Badge>
                </HtmlTooltip>
                :
                <IconButton sx={{borderRadius: '0', height: '87px'}} disabled />
            }
        </Box>
     </Grid>
    );
}