import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Badge from '@mui/material/Badge';
import Xarrow from 'react-xarrows';
import SkillButton from './skill-button.jsx';

export default function AmazonSkillTree(props) {

    const { skills, allocSkills, setAllocSkills, remainingSkillPoints, ...other } = props;

    let skillTreeImg = "./skillTreeBg-2.png";

    return (
        <Box>
            {skills &&
            <Grid container spacing={1}>
                <Grid item className="skillTreeTab" sm={4} md={4} lg={4} xl={4} sx={{ background: `url(${skillTreeImg}) rgba(50,50,50,.1)`, backgroundSize: "contain", border: 'solid 10px #191919', textAlign: 'center'}}>
                    <Toolbar position="relative" sx={{backgroundColor: 'rgba(90,90,90,0.8)', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', border: 'solid 5px #303030', color: '#FFF', justifyContent: 'center', top: '-28px', width: '75%'}}>
                        <Typography variant="subtitle2" component="div">
                            Javalin & Spear Skills
                        </Typography>
                    </Toolbar>
                    <Grid container spacing={1}>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[0]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[1]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[2]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[3]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[4]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[5]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[6]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[7]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[8]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[9]}/>
                    </Grid>
                </Grid>
                <Grid item className="skillTreeTab" sm={4} md={4} lg={4} xl={4} sx={{ background: `url(${skillTreeImg}) rgba(50,50,50,.1)`, backgroundSize: "contain", border: 'solid 10px #191919', textAlign: 'center'}}>
                    <Toolbar position="relative" sx={{backgroundColor: 'rgba(90,90,90,0.8)', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', border: 'solid 5px #303030', color: '#FFF', justifyContent: 'center', top: '-28px', width: '75%'}}>
                        <Typography variant="subtitle2" component="div">
                            Passive & Magic Skills
                        </Typography>
                    </Toolbar>
                    <Grid container spacing={1}>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[10]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[11]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[12]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[13]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[14]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[16]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[15]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[17]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[18]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                    </Grid>
                </Grid>
                <Grid item className="skillTreeTab" sm={4} md={4} lg={4} xl={4} sx={{ background: `url(${skillTreeImg}) rgba(50,50,50,.1)`, backgroundSize: "contain", border: 'solid 10px #191919', textAlign: 'center'}}>
                    <Toolbar position="relative" sx={{backgroundColor: 'rgba(90,90,90,0.8)', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', border: 'solid 5px #303030', color: '#FFF', justifyContent: 'center', top: '-28px', width: '75%'}}>
                        <Typography variant="subtitle2" component="div">
                            Bow & Crossbow Skills
                        </Typography>
                    </Toolbar>
                    <Grid container spacing={1}>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[19]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[22]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[20]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[21]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[23]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[24]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[25]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[26]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[28]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[27]}/>
                    </Grid>
                </Grid>
            </Grid>}
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Jab"
                end="Javelin and Spear Mastery"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Jab"
                end="Power Strike"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Poison Javelin"
                end="Lightning Bolt"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Javelin and Spear Mastery"
                end="Fend"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Power Strike"
                end="Charged Strike"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Power Strike"
                end="Lightning Bolt"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Lightning Bolt"
                end="Plague Javelin"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Charged Strike"
                end="Lightning Strike"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Plague Javelin"
                end="Lightning Fury"
                startAnchor="bottom"
                endAnchor="top" />



            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Inner Sight"
                end="Slow Movement"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Critical Strike"
                end="Pierce"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Evade"
                end="Dodge"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Slow Movement"
                end="Dodge"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Slow Movement"
                end="Decoy"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Pierce"
                end="Penetrate"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Decoy"
                end="Valkyrie"
                startAnchor="bottom"
                endAnchor="top" />



            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Magic Arrow"
                end="Cold Arrow"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Magic Arrow"
                end="Multiple Shot"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Magic Arrow"
                end="Fire Arrow"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Cold Arrow"
                end="Ice Arrow"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Multiple Shot"
                end="Guided Arrow"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Fire Arrow"
                end="Exploding Arrow"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Ice Arrow"
                end="Freezing Arrow"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Guided Arrow"
                end="Strafe"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Exploding Arrow"
                end="Immolation Arrow"
                startAnchor="bottom"
                endAnchor="top" />

        </Box>
    );

}