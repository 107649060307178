import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Badge from '@mui/material/Badge';
import Xarrow from 'react-xarrows';
import SkillButton from './skill-button.jsx';

export default function DruidSkillTree(props) {

    const { skills, allocSkills, setAllocSkills, remainingSkillPoints, ...other } = props;

    let skillTreeImg = "./skillTreeBg-2.png";

    return (
        <Box>
            {skills &&
            <Grid container spacing={1}>
                <Grid item className="skillTreeTab" sm={4} md={4} lg={4} xl={4} sx={{ background: `url(${skillTreeImg}) rgba(50,50,50,.1)`, backgroundSize: "contain", border: 'solid 10px #191919', textAlign: 'center'}}>
                    <Toolbar position="relative" sx={{backgroundColor: 'rgba(90,90,90,0.8)', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', border: 'solid 5px #303030', color: '#FFF', justifyContent: 'center', top: '-28px', width: '75%'}}>
                        <Typography variant="subtitle2" component="div">
                            Elemental Skills
                        </Typography>
                    </Toolbar>
                    <Grid container spacing={1}>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[88]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[89]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />


                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[90]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[91]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[92]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[93]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[94]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[95]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[96]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[98]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[97]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                    </Grid>
                </Grid>
                <Grid item className="skillTreeTab" sm={4} md={4} lg={4} xl={4} sx={{ background: `url(${skillTreeImg}) rgba(50,50,50,.1)`, backgroundSize: "contain", border: 'solid 10px #191919', textAlign: 'center'}}>
                    <Toolbar position="relative" sx={{backgroundColor: 'rgba(90,90,90,0.8)', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', border: 'solid 5px #303030', color: '#FFF', justifyContent: 'center', top: '-28px', width: '75%'}}>
                        <Typography variant="subtitle2" component="div">
                            Shapeshift Skills
                        </Typography>
                    </Toolbar>
                    <Grid container spacing={1}>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[99]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[101]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[100]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[102]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[103]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[104]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[105]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[106]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[107]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[108]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                    </Grid>
                </Grid>
                <Grid item className="skillTreeTab" sm={4} md={4} lg={4} xl={4} sx={{ background: `url(${skillTreeImg}) rgba(50,50,50,.1)`, backgroundSize: "contain", border: 'solid 10px #191919', textAlign: 'center'}}>
                    <Toolbar position="relative" sx={{backgroundColor: 'rgba(90,90,90,0.8)', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', border: 'solid 5px #303030', color: '#FFF', justifyContent: 'center', top: '-28px', width: '75%'}}>
                        <Typography variant="subtitle2" component="div">
                            Summoning Skills
                        </Typography>
                    </Toolbar>
                    <Grid container spacing={1}>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[109]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[110]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[111]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[112]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[113]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[114]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[115]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[116]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[117]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[118]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                    </Grid>
                </Grid>
            </Grid>}
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Firestorm"
                end="Molten Boulder"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Arctic Blast"
                end="Cyclone Armor"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Molten Boulder"
                end="Fissure"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Cyclone Armor"
                end="Twister"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Cyclone Armor"
                end="Gust"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Fissure"
                end="Volcano"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Twister"
                end="Tornado"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Volcano"
                end="Armageddon"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Tornado"
                end="Hurricane"
                startAnchor="bottom"
                endAnchor="top" />



            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Werewolf"
                end="Feral Rage"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Werebear"
                end="Maul"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Feral Rage"
                end="Rabies"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Maul"
                end="Hunger"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Maul"
                end="Shock Wave"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Rabies"
                end="Fury"
                startAnchor="bottom"
                endAnchor="top" />



            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Raven"
                end="Summon Spirit Wolf"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Poison Creeper"
                end="Carrion Vine"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Heart of Wolverine"
                end="Spirit of Barbs"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Heart of Wolverine"
                end="Summon Spirit Wolf"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Summon Spirit Wolf"
                end="Summon Dire Wolf"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Carrion Vine"
                end="Solar Creeper"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Spirit of Barbs"
                end="Oak Sage"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Summon Dire Wolf"
                end="Summon Grizzly"
                startAnchor="bottom"
                endAnchor="top" />

        </Box>
    );

}