import * as React from 'react';
import ReactDOM from 'react-dom/client';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CenteredTabs from './nav-tabs.jsx';
import NewCharacterPlanner from './new-planner.jsx';
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline, Typography } from "@mui/material";

const theme = createTheme({
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
        },
      },
    typography: {
        fontSize: 28,
        color: '#909090',
    },
    palette: {
        primary: {
            main: '#a53030',
        },
        secondary: {
            main: '#000',
        },
        text: {
            primary: '#000',
            secondary: '#FFF',
        },
        background: {
            default: '#191919',
        },
    },
});

theme.typography.h6 = {
  fontSize: '1.5rem',
  '@media (min-width:600px)': {
    fontSize: '1.4rem',
  },
  [theme.breakpoints.up('lg')]: {
      fontSize: '1.6rem',
    },
};

theme.typography.caption = {
  fontSize: '.7rem',
  '@media (min-width:600px)': {
    fontSize: '.8rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
  },
};

theme.typography.subtitle2 = {
  fontSize: '1rem',
  '@media (min-width:600px)': {
    fontSize: '1rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.3rem',
  },
};

const queryClient = new QueryClient();

export default function App() {

    return (
    <Typography component="div">
        <ThemeProvider theme={theme}>
            <CssBaseline>
{/*                 <NewCharacterPlanner /> */}
                <CenteredTabs />
            </CssBaseline>
        </ThemeProvider>
    </ Typography>
    );
}



const container = document.getElementById("react");
const root = ReactDOM.createRoot(container);
root.render(<QueryClientProvider client={queryClient}><App /></QueryClientProvider>);
