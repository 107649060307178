import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Badge from '@mui/material/Badge';
import Xarrow from 'react-xarrows';
import SkillButton from './skill-button.jsx';

export default function SorceressSkillTree(props) {

    const { skills, allocSkills, setAllocSkills, remainingSkillPoints, ...other } = props;

    let skillTreeImg = "./skillTreeBg-2.png";

    return (
        <Box>
            {skills &&
            <Grid container spacing={1}>
                <Grid item className="skillTreeTab" sm={4} md={4} lg={4} xl={4} sx={{ background: `url(${skillTreeImg}) rgba(50,50,50,.1)`, backgroundSize: "contain", border: 'solid 10px #191919', textAlign: 'center'}}>
                    <Toolbar position="relative" sx={{backgroundColor: 'rgba(90,90,90,0.8)', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', border: 'solid 5px #303030', color: '#FFF', justifyContent: 'center', top: '-28px', width: '75%'}}>
                        <Typography variant="subtitle2" component="div">
                            Cold Skills
                        </Typography>
                    </Toolbar>
                    <Grid container spacing={1}>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[185]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[186]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />


                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[187]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[188]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[189]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[190]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[193]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[192]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[191]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[194]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[195]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                    </Grid>
                </Grid>
                <Grid item className="skillTreeTab" sm={4} md={4} lg={4} xl={4} sx={{ background: `url(${skillTreeImg}) rgba(50,50,50,.1)`, backgroundSize: "contain", border: 'solid 10px #191919', textAlign: 'center'}}>
                    <Toolbar position="relative" sx={{backgroundColor: 'rgba(90,90,90,0.8)', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', border: 'solid 5px #303030', color: '#FFF', justifyContent: 'center', top: '-28px', width: '75%'}}>
                        <Typography variant="subtitle2" component="div">
                            Lightning Skills
                        </Typography>
                    </Toolbar>
                    <Grid container spacing={1}>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[198]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[196]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[197]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[199]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[200]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[201]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[203]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[202]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[205]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[204]}/>
                    </Grid>
                </Grid>
                <Grid item className="skillTreeTab" sm={4} md={4} lg={4} xl={4} sx={{ background: `url(${skillTreeImg}) rgba(50,50,50,.1)`, backgroundSize: "contain", border: 'solid 10px #191919', textAlign: 'center'}}>
                    <Toolbar position="relative" sx={{backgroundColor: 'rgba(90,90,90,0.8)', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', border: 'solid 5px #303030', color: '#FFF', justifyContent: 'center', top: '-28px', width: '75%'}}>
                        <Typography variant="subtitle2" component="div">
                            Combat Skills
                        </Typography>
                    </Toolbar>
                    <Grid container spacing={1}>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[208]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[206]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[207]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[209]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[210]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[211]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[212]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[213]}/>

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[214]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[215]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />

                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} />
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[217]}/>
                        <SkillButton allocSkills={allocSkills} setAllocSkills={setAllocSkills} remainingSkillPoints={remainingSkillPoints} skill={skills[216]}/>
                    </Grid>
                </Grid>
            </Grid>}
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Ice Bolt"
                end="Ice Blast"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Cold Enchant"
                end="Shiver Armor"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Frost Nova"
                end="Blizzard"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Ice Blast"
                end="Glacial Spike"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Ice Blast"
                end="Shiver Armor"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Shiver Armor"
                end="Chilling Armor"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Glacial Spike"
                end="Blizzard"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Glacial Spike"
                end="Ice Barrage"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Blizzard"
                end="Frozen Orb"
                startAnchor="bottom"
                endAnchor="top" />



            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Charged Bolt"
                end="Nova"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Charged Bolt"
                end="Lightning"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Telekinesis"
                end="Teleport"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Nova"
                end="Thunder Storm"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Lightning"
                end="Thunder Storm"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Lightning"
                end="Chain Lightning"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Teleport"
                end="Energy Shield"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Chain Lightning"
                end="Energy Shield"
                startAnchor="bottom"
                endAnchor="top" />


            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Inferno"
                end="Blaze"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Fire Bolt"
                end="Fire Ball"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Warmth"
                end="Lesser Hydra"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Blaze"
                end="Fire Wall"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Fire Wall"
                end="Meteor"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Fire Ball"
                end="Meteor"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Fire Ball"
                end="Combustion"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Fire Ball"
                end="Enchant Fire"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Lesser Hydra"
                end="Enchant Fire"
                startAnchor="bottom"
                endAnchor="top" />
            <Xarrow
                path="straight"
                color="#10101099"
                strokeWidth={12}
                headSize={3}
                start="Enchant Fire"
                end="Hydra"
                startAnchor="bottom"
                endAnchor="top" />

        </Box>
    );

}